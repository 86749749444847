/** 
 * @moduleName: application utiliies and helper styles
 * @createdDate: 08-aug-2020
 * @author: senthilkumar-r
 * @copyright: GeTS
 */

/* 
 * This stylesheet has useful helpers and utility style declarations
*/

@charset "utf-8";

@import './skin';

//SaSS Variables
$sizes: 0, 1, 2, 3, 4, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75,
  80, 85, 90, 95, 100;
$widthInPx: 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 110, 120, 130,
  140, 150, 160, 170, 180, 190, 200;
$texttransform-props: none, uppercase, lowercase, capitalize;
$fontSizes: 12, 14, 16, 20, 24, 28, 32;

// Remove X icon from search textfiled
input[type='search']::-webkit-clear-button,
input[type='text']::-webkit-clear-button {
  display: none !important;
}

//Width in pixel
@each $size in $widthInPx {
  .w-#{$size}px {
    width: $size + px !important;
  }
}

//Width in percentage
@each $size in $sizes {
  .w-#{$size} {
    width: $size#{'%'};
  }
}

//Height in percentage
@each $size in $sizes {
  .h-#{$size} {
    height: $size#{'%'};
  }
}

/* [Margin] --- Starts Here */
//Margin Top
@each $size in $sizes {
  .mt-#{$size} {
    margin-top: $size + px !important;
  }
}

//Margin Right
@each $size in $sizes {
  .mr-#{$size} {
    margin-right: $size + px !important;
  }
}

//Margin Bottom
@each $size in $sizes {
  .mb-#{$size} {
    margin-bottom: $size + px !important;
  }
}

//Margin Left
@each $size in $sizes {
  .ml-#{$size} {
    margin-left: $size + px !important;
  }
}

/* [Margin] --- Ends Here */

/* [Padding] --- Starts Here */
//Padding Top
@each $size in $sizes {
  .pt-#{$size} {
    padding-top: $size + px !important;
  }
}

//Padding Right
@each $size in $sizes {
  .pr-#{$size} {
    padding-right: $size + px !important;
  }
}

//Padding Bottom
@each $size in $sizes {
  .pb-#{$size} {
    padding-bottom: $size + px !important;
  }
}

//Padding Left
@each $size in $sizes {
  .pl-#{$size} {
    padding-left: $size + px !important;
  }
}

/* [Padding] --- Ends Here */

/* [Text Related] --- Starts Here */
@each $transformprop in $texttransform-props {
  .texttransform-#{$transformprop} {
    text-transform: $transformprop !important;
  }
}

// Font sizes
@each $size in $fontSizes {
  .font-#{$size} {
    font-size: $size + px !important;
  }
}

// Setting font-size for Font Awesome icons
@each $size in $fontSizes {
  .list-icon-#{$size} {
    font-size: $size + px !important;
  }
}

// Text Colors
.color-primary-blue {
  color: $primary-blue !important;
}

.color-primary-dark {
  color: $primary-dark !important;
}

.color-primary-gray {
  color: $primary-gray !important;
}

.color-secondary-orange {
  color: $secondary-orange !important;
}

.color-secondary-green {
  color: $secondary-green !important;
}

.color-secondary-red {
  color: $secondary-red !important;
}

.color-secondary-aqua {
  color: $secondary-aqua !important;
}

.color-secondary-violet {
  color: $secondary-violet !important;
}

// Font Weight
.font-light {
  font-weight: 100 !important;
}

.font-regular {
  font-weight: 300 !important;
}

.font-medium {
  font-weight: 400 !important;
}

.font-mediumBold {
  font-weight: 600 !important;
}

.font-bolder {
  font-weight: 900 !important;
}

// Headers
.section-preheader {
  font-size: 14px !important;
  @extend .font-bolder;
  // color: #1C1D2133 !important;
  @extend .texttransform-uppercase;
}

.section-header {
  font-size: 32px !important; //As per style guide 26px
  @extend .font-bolder;
  // color: $primary-dark !important;
}

.section-subheader {
  font-size: 16px !important;
  @extend .font-bolder;
  // color: $primary-dark !important;
  @extend .texttransform-uppercase;
}

.section-body-subheader {
  font-size: 18px !important;
  @extend .font-bolder;
}

.section-text {
  font-size: 18px !important; //As per style guide 14px
  @extend .font-medium;
  // color: $primary-dark !important;
}

/* [Text Related] --- Ends Here */

/* [Application Icons] --- Starts Here */
.label-icon {
  margin-right: 5px;

  &.ic-32 {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  &::before {
    content: '';
    width: 16px;
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &.ic-bg-white {
    background-color: rgba(0, 0, 0, 0.3);
  }

  &.icon-arrow-back-white {
    &::before {
      background-image: url('../img/app-icons/ic_arrow_back_24px-white.svg');
    }
  }
}

/* [Application Icons] --- Ends Here */

/* [Hyperlinks] --- Starts Here */
.ng-link-primary {
  color: $primary-blue !important;
  font-weight: 700 !important;

  &.font-medium {
    font-weight: 400 !important;
  }
}

.list-link-prefix-icon {
  font-size: 14px;
  font-weight: 700;
  color: $space-black;

  .list-fa-icon {
    margin-right: 10px !important;
  }
}

// Used at inner pages banner overlay CTA
.ng-link-button {
  font-size: 16px;
  font-weight: 400;
  color: $white;

  svg:first-child {
    margin-right: 3px;
    width: 16px;
    height: 16px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    left: 0;
    transition: left 0.2s ease-in;
    position: relative;
  }

  &:hover {
    .button-adornment {
      left: -3px;
    }
  }
}
/* [Hyperlinks] --- Ends Here */

/* [Flex] --- Starts Here */
//Align Items --- Starts Here
@mixin flex-xy {
  display: flex;
  align-items: center;
}

@mixin flex-y {
  display: flex;
  align-items: baseline;
}

.align-right {
  @include flex-xy;
  justify-content: flex-end;
}

.align-left {
  @include flex-xy;
  justify-content: flex-start;
}

.align-center {
  @include flex-xy;
  justify-content: center;
}

.align-bottom {
  @include flex-y;
}
//Align Items --- Ends Here

.flex-grow {
  flex-grow: 1;
}

/* [Flex] --- Ends Here */

/* [Display] --- Starts Here */
$display: none, block;
$visibility: hidden, visible;

@each $view in $display {
  .d-#{$view} {
    display: $view;
  }
}

@each $view in $display {
  .v-#{$view} {
    visibility: $view;
  }
}
/* [Display] --- Ends Here */

/* [Header / Titles / No Data Text] --- Starts Here */
.popover-panel-title {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: currentColor !important;
}

.nodata-found-text {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: $pinkred !important;
}

/* [Header / Titles / No Data Text] --- Ends Here */

/* [Breadcrumbs] --- Starts Here */
.ng-breadcrumb-wrapper {
  li {
    a {
      color: $primary-blue;
      font-size: 12px;
    }

    p {
      opacity: 0.3;
      font-size: 12px;
    }
  }

  .breadcrumb-separator {
    font-size: 16px;
    margin-left: 0;
    margin-right: 0;
  }
}

/* [Breadcrumbs] --- Ends Here */

/* [Cicular Buttons] --- Starts Here */
// Refer: Application Topbar like Notificaitons, Settings etc.
.btn-circular {
  border: 1px transparent solid !important;
  border-radius: 50% !important;

  /* &:hover{
      background-color: $white !important;
  } */

  &.active {
    background-color: $white !important;
    border-color: $circular-bgn-active-border-color !important;

    svg {
      color: $primary-blue;
    }
  }
}

/* [Cicular Buttons] --- Ends Here */

/* [Popover] --- Starts Here */
// Notification List
.popover-list {
  .popover-list__item {
    margin: 5px;
    border-bottom: 0;
    border-radius: 11px;
    padding: 5px;
    width: calc(100% - 10px);
    transition: background-color 0.3s ease-in;

    &:hover,
    &.active {
      background-color: $popover-list-hover-bg;
    }

    .list-avatar {
      border-radius: 8px !important;
      border: 1px $avatar-border-color solid !important;
      background-color: $white !important;

      .list-avatar__icon {
        stroke: $avatar-icon-fill;

        svg {
          stroke: $avatar-icon-fill;
        }
      }
    }

    .list-desc-wrapper {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

    .list-primary-title {
      color: $popover-list-primary-title-color;
      font-weight: 700;
    }

    .list-seconday-title {
      color: $popover-list-secondary-title-color;
      font-weight: 400;
    }
  }
}

// User Settings List
.user-settings-popover-list {
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .user-settings-popover-list__link-item {
    border-radius: 5px;
    transition: background-color 0.3s ease-in;
    margin: 3px 0;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    padding-left: 8px;
    padding-right: 8px;

    .list-fa-icon {
      transition: color 0.1s ease-in;
    }

    &:hover,
    &.active {
      background-color: $popover-list-hover-bg;

      .list-fa-icon {
        color: $primary-blue;
      }
    }
  }
}

/* [Popover] --- Ends Here */
